export const environment = {
  production: false,
  baseURL:"https://ppe-flightload.adminmodules.etihad.ae/ada-services/flightloadadmin",
  OKTAClientID: "0oa25po5zvrlURhoD0h8",
  OKTAIssuer: "https://etihad.oktapreview.com",
  OKTArediRectUri: "https://ppe-flightload.adminmodules.etihad.ae/login/callback",
  coreDataURL: "https://www.ppe.etihad.com",
  rmToolCookieName: 'rm-tool-ppe-jwt',
  env: "PPE",
  secretKeyEmail: 'rev-man-email-key',
};
